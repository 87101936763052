import { AssetClass } from '../interfaces/assets.interface';
import {
  BrickRelationshipType,
  BrickRelationshipTypeOptions,
  EntityActivityCategory,
} from '../interfaces/entity-management.interface';
import { ASSET_CRITICALITY_MAP } from './assets.constants';

export const MaxEntityCount = 50;
export const EntityManagementLocationFields = ['building', 'floor', 'room'];

export const EntityManagementCriticalityOptions = [
  {
    label: ASSET_CRITICALITY_MAP.get(5),
    value: 5,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(4),
    value: 4,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(3),
    value: 3,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(2),
    value: 2,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(1),
    value: 1,
  },
  { label: ASSET_CRITICALITY_MAP.get(0), value: 0 },
];

export const EntityManagementDetailsTabs = [
  { name: 'Activity', isVisible: true },
  { name: 'Recent History', isVisible: true },
  { name: 'Details', isVisible: true },
  { name: 'Relationships', isVisible: true },
];
export const EntityHistoryFilters = [
  { label: 'Entity details', value: EntityActivityCategory.EntityDetails },
  { label: 'Relationships', value: EntityActivityCategory.Relationships },
  { label: 'Alarm details', value: EntityActivityCategory.AlarmDetails },
  { label: 'Work', value: EntityActivityCategory.Work },
];

export const AddEntityRelationshipOptions = [
  {
    label: BrickRelationshipTypeOptions.IsFedEquipment,
    value: BrickRelationshipTypeOptions.IsFedEquipment,
  },
  {
    label: BrickRelationshipTypeOptions.IsFedSystem,
    value: BrickRelationshipTypeOptions.IsFedSystem,
  },
  {
    label: BrickRelationshipTypeOptions.IsFedSpace,
    value: BrickRelationshipTypeOptions.IsFedSpace,
  },
  {
    label: BrickRelationshipTypeOptions.FeedsEquipment,
    value: BrickRelationshipTypeOptions.FeedsEquipment,
  },
  {
    label: BrickRelationshipTypeOptions.FeedsSystem,
    value: BrickRelationshipTypeOptions.FeedsSystem,
  },
  {
    label: BrickRelationshipTypeOptions.FeedsSpace,
    value: BrickRelationshipTypeOptions.FeedsSpace,
  },
  {
    label: BrickRelationshipTypeOptions.IsPartEquipment,
    value: BrickRelationshipTypeOptions.IsPartEquipment,
  },
  {
    label: BrickRelationshipTypeOptions.IsPartSystem,
    value: BrickRelationshipTypeOptions.IsPartSystem,
  },
  {
    label: BrickRelationshipTypeOptions.HasPartEquipment,
    value: BrickRelationshipTypeOptions.HasPartEquipment,
  },
  {
    label: BrickRelationshipTypeOptions.HasPartSystem,
    value: BrickRelationshipTypeOptions.HasPartSystem,
  },
];

export const AddRelationshipValueDictionary = new Map<
  BrickRelationshipTypeOptions,
  BrickRelationshipType
>()
  .set(
    BrickRelationshipTypeOptions.IsFedEquipment,
    BrickRelationshipType.IsFedBy
  )
  .set(BrickRelationshipTypeOptions.IsFedSystem, BrickRelationshipType.IsFedBy)
  .set(BrickRelationshipTypeOptions.IsFedSpace, BrickRelationshipType.IsFedBy)
  .set(BrickRelationshipTypeOptions.FeedsEquipment, BrickRelationshipType.Feeds)
  .set(BrickRelationshipTypeOptions.FeedsSystem, BrickRelationshipType.Feeds)
  .set(BrickRelationshipTypeOptions.FeedsSpace, BrickRelationshipType.Feeds)
  .set(
    BrickRelationshipTypeOptions.IsPartEquipment,
    BrickRelationshipType.IsPartOf
  )
  .set(
    BrickRelationshipTypeOptions.IsPartSystem,
    BrickRelationshipType.IsPartOf
  )
  .set(
    BrickRelationshipTypeOptions.HasPartEquipment,
    BrickRelationshipType.HasPart
  )
  .set(
    BrickRelationshipTypeOptions.HasPartSystem,
    BrickRelationshipType.HasPart
  );

export const AddRelationshipFilterDictionary = new Map<
  BrickRelationshipTypeOptions,
  { [name: string]: AssetClass[] }
>()
  .set(BrickRelationshipTypeOptions.IsFedEquipment, {
    class: [AssetClass.Equipment],
  })
  .set(BrickRelationshipTypeOptions.IsFedSystem, { class: [AssetClass.System] })
  .set(BrickRelationshipTypeOptions.IsFedSpace, { class: [AssetClass.Space] })
  .set(BrickRelationshipTypeOptions.FeedsEquipment, {
    class: [AssetClass.Equipment],
  })
  .set(BrickRelationshipTypeOptions.FeedsSystem, { class: [AssetClass.System] })
  .set(BrickRelationshipTypeOptions.FeedsSpace, { class: [AssetClass.Space] })
  .set(BrickRelationshipTypeOptions.IsPartEquipment, {
    class: [AssetClass.Equipment],
  })
  .set(BrickRelationshipTypeOptions.IsPartSystem, {
    class: [AssetClass.System],
  })
  .set(BrickRelationshipTypeOptions.HasPartEquipment, {
    class: [AssetClass.Equipment],
  })
  .set(BrickRelationshipTypeOptions.HasPartSystem, {
    class: [AssetClass.System],
  });

export const NOTIFICATION_ORDER = {
  field: 'NAME',
  direction: 'ASC',
};

export enum notificationModalMode {
  CREATE = 'create notification',
  EDIT = 'edit notification',
}

export enum NotificationTimeType {
  Always = '24/7',
  Schedule = 'schedule',
}

export const INSTANTANEOUSLY_WARNING =
  'WARNING: Instantaneous notification of reoccurring alarms may cause excessive emails and push notification messages to be sent.';

export const ALLOW_NOTIFICATION_LENS_TYPE = ['alarms', 'asset'];

export const DISABLE_NOTIFICATION_CATEGORIES = ['all_alarms', 'all_assets'];

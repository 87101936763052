import { Injectable } from '@angular/core';
import {
  GlobalFilters,
  GlobalFilterSort,
  CoreAlarmsOrderOptions,
  CoreAlarmsOrderSortField,
  CoreAlarmsOrderSortDirection,
  CoreAlarmsWhere,
  WorkTicketWhere,
  OrderDirection,
  WorkTicketSortField,
  CriterionSelection,
  AlarmProperty,
  CoreAlarmsFiltersWhere,
  CoreWorkFiltersWhere,
  StaleStatus,
  AUTOMATION_RULE_CREATOR,
  WorkTicketStateValues,
  WORK_RESOLUTION_VALUES,
  AlarmPriorityNames,
  Users,
  ResolutionKeys,
  SelectDropdownOption,
  Sort,
  WorkTicketOrder,
  BLANK_VALUE,
  ASSET_CRITICALITY_MAP,
  AssetWhere,
  BasFilters,
} from '@vfi-ui/models';
import {
  safeParseNull,
  safeParseBoolean,
  snakeCase,
  groupBy,
  isNil,
  remove,
  fastParse,
  formatNuisance,
  head,
  last,
  titleCase,
  generateMinMaxFilterPillText,
} from '@vfi-ui/util/helpers';
import { format, milliseconds } from 'date-fns';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalFiltersService {
  filtersUpdated = new Subject<boolean>();
  constructor() {}

  /**
   * emit filters updated event
   *
   * @memberof GlobalFiltersService
   */
  onFiltersUpdate() {
    this.filtersUpdated.next(true);
  }

  /**
   * create where clause based off type
   *
   * @param {GlobalFilters} applied
   * @param {string} type
   * @param {string} [room]
   * @returns
   * @memberof CoreAlarmsFiltersWhere
   */
  createFiltersWhere(
    applied: GlobalFilters,
    alarmProperties: AlarmProperty[]
  ): CoreAlarmsFiltersWhere {
    const grouped = groupBy(
      applied.criterion,
      (d: CriterionSelection) => d?.detail?.isExclusion || false
    );
    const createdWhere: CoreAlarmsFiltersWhere = {};
    if (grouped?.false) {
      createdWhere.where = this.generateAlarmsFilterQueries(
        { ...applied, criterion: grouped?.false },
        alarmProperties
      );
    }
    if (grouped?.true) {
      createdWhere.whereNot = this.generateAlarmsFilterQueries(
        { ...applied, criterion: grouped?.true },
        alarmProperties
      );
    }

    return createdWhere;
  }

  /**
   * create where clause based off type for work
   *
   * @param {GlobalFilters} applied
   * @returns
   * @memberof CoreWorkFiltersWhere
   */
  createWorkFiltersWhere(applied: GlobalFilters): CoreWorkFiltersWhere {
    const grouped = groupBy(
      applied.criterion,
      (d: CriterionSelection) => d?.detail?.isExclusion || false
    );
    const createdWhere: CoreWorkFiltersWhere = {};
    if (grouped?.false) {
      createdWhere.where = this.generateWorkFilterQueries({
        ...applied,
        criterion: grouped?.false,
      });
    }
    if (grouped?.true) {
      createdWhere.whereNot = this.generateWorkFilterQueries({
        ...applied,
        criterion: grouped?.true,
      });
    }

    return createdWhere;
  }

  /**
   * create order by clause based of sort value for work
   *
   * @param { GlobalFilterSort } sort
   * @returns {WorkTicketOrder}
   * @memberof GlobalFiltersService
   */
  createWorkSortOrderBy(sort: GlobalFilterSort | Sort[]): WorkTicketOrder[] {
    if (Array.isArray(sort)) {
      return sort.map((s) => ({
        field: s.field,
        direction: s.direction as OrderDirection,
      }));
    }

    return [
      {
        field: WorkTicketSortField[sort.sortDbName],
        direction:
          OrderDirection[sort.sortType.toUpperCase() as OrderDirection],
      },
    ];
  }

  /**
   * create order by clause based of sort value
   *
   * @param { GlobalFilterSort } sort
   * @returns {CoreAlarmsOrderOptions}
   * @memberof GlobalFiltersService
   */
  createSortOrderBy(sort: GlobalFilterSort): CoreAlarmsOrderOptions {
    const data: CoreAlarmsOrderOptions = {
      field: sort.sortDbName as CoreAlarmsOrderSortField,
      direction: CoreAlarmsOrderSortDirection[sort.sortType],
    };
    if (sort.sortValue2) {
      data.field2 = sort.sortValue2;
    }
    return data;
  }

  /**
   * format criterions to match display options
   *
   * @param {*} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  formatCriterions(criterion) {
    return criterion.map((crit) =>
      typeof crit?.model === 'string'
        ? crit
        : { ...crit, model: crit?.model?.name }
    );
  }

  /**
   * generate asset filter queries
   *
   * @param {GlobalFilters} filters
   * @returns {Partial<AssetWhere>}
   * @memberof GlobalFiltersService
   */
  generateAssetFilterQueries(filters: GlobalFilters): Partial<AssetWhere> {
    let where: Partial<AssetWhere> = {};

    if (filters.searchTerm) {
      where = {
        ...where,
        nameLike: `%${filters.searchTerm}%`,
      };
    }

    if (filters.criterion.length) {
      filters.criterion.forEach((criterion) => {
        if (criterion.detail) {
          if (criterion.detail.dbName === 'isActive') {
            where = this.valueBoolWhere(where, criterion);
          } else if (criterion.detail.dbName === 'ids') {
            where = {
              ...where,
              [criterion.detail.dbName]: criterion.detail.selection.value.map(
                (id) => +id
              ),
            };
          } else if (criterion.detail.dbName === 'name') {
            where = {
              ...where,
              name: criterion.detail.selection.name,
            };
          } else if (
            criterion.detail.dbName === 'class' ||
            criterion.detail.dbName === 'source' ||
            criterion.detail.dbName === 'status'
          ) {
            where = {
              ...where,
              [criterion.detail.dbName]: criterion.value,
            };
          } else if (criterion.detail.dbName === 'assetCriticality') {
            where = {
              ...where,
              criticality: criterion.value.map((crit) => +crit),
            };
          } else if (criterion.detail.dbName === 'spaceTypes') {
            where = {
              ...where,
              spaceType: criterion.detail.selection.value.map((v) =>
                v === 'null' ? null : v
              ),
            };
          } else if (criterion.detail.dbName === 'label') {
            where = {
              ...where,
              label: criterion.detail.selection.value.map((v) =>
                v === 'null' ? null : v
              ),
            };
          } else if (
            ['type', 'building', 'floor', 'room'].includes(
              criterion.detail.dbName
            )
          ) {
            where = {
              ...where,
              [criterion.detail.dbName]:
                criterion.detail.selection.name ||
                criterion.detail.selection.value,
            };
          }
        }
      });
    }
    return where;
  }

  /**
   * returns filter pill text
   *
   * @param {CriterionSelection} crit
   * @returns
   * @memberof GlobalFiltersService
   */
  getFilterPillText(crit: CriterionSelection) {
    return this.generateFilterPillText(crit);
  }

  /**
   * return resolution isResolved value
   *
   * @param {string[]} selection
   * @returns
   * @memberof GlobalFiltersService
   */
  getResolutionValue(selection: string[]) {
    let isResolved;
    const values = WORK_RESOLUTION_VALUES;
    if (
      selection.includes(values.Resolved) &&
      selection.includes(values.Unresolved)
    ) {
      isResolved = undefined;
    } else if (selection.length === 1 && selection.includes(values.Resolved)) {
      isResolved = true;
    } else if (
      selection.length === 1 &&
      selection.includes(values.Unresolved)
    ) {
      isResolved = false;
    }
    return isResolved;
  }

  /**
   * generate text for filter pills
   *
   * @private
   * @param {CriterionSelection} crit
   * @returns
   * @memberof GlobalFiltersService
   */
  private generateFilterPillText(crit: CriterionSelection) {
    let text: string | string[];
    if (
      crit?.detail?.type === 'minMax' ||
      crit?.detail?.type === 'durationMinMax' ||
      crit?.detail?.type === 'timeMinMax'
    ) {
      text = generateMinMaxFilterPillText(crit);
    } else if (crit?.detail?.type === 'dateTime') {
      text = this.getDateTimeSelectPillText(crit?.detail?.selection);
    } else if (crit?.detail?.type === 'customValueSelect') {
      text = this.getCustomValueSelectPillText(
        crit?.value,
        crit?.detail?.dbName,
        crit?.detail?.selectValues
      );
    } else if (
      crit?.detail?.type === 'userSelect' &&
      crit?.detail?.dbName !== 'createdBy'
    ) {
      text = this.getAssigneeSelectPillText(
        crit?.detail?.selection?.value,
        crit?.detail?.selectValues
      );
    } else if (
      crit?.detail?.type === 'userSelect' &&
      crit?.detail?.dbName === 'createdBy'
    ) {
      text = this.getAssigneeByLabelPillText(
        crit?.detail?.selection?.value,
        crit?.detail?.selectValues
      );
    } else if (['alarmSource', 'cmmsSource'].includes(crit?.detail?.type)) {
      text = this.getMultiSelectPillText(crit?.detail?.selection?.name);
    } else if (crit?.detail?.type === 'priority') {
      text = this.getMultiSelectPillText(
        crit?.detail?.selection?.value.map((p) => AlarmPriorityNames[p])
      );
    } else if (crit?.detail?.type === 'bool') {
      text = titleCase(crit?.value.toString());
    } else if (
      crit?.detail?.type === 'select' ||
      crit?.detail?.type === 'status'
    ) {
      text = this.getMultiSelectPillText(crit?.detail?.selection?.value);
    } else {
      text = crit?.detail?.selection?.value;
    }
    return `${crit?.detail?.name}: ${text}`;
  }

  /**
   * format date time select pill text
   *
   * @private
   * @param {*} selection
   * @returns
   * @memberof GlobalFiltersService
   */
  private getDateTimeSelectPillText(selection) {
    return `Between ${format(new Date(selection?.min), 'PP')} and ${format(
      new Date(selection?.max),
      'PP'
    )}`;
  }

  /**
   * format text for multi select pill text
   *
   * @private
   * @param {string[]} value
   * @returns
   * @memberof GlobalFiltersService
   */
  private getMultiSelectPillText(value: string[]) {
    const values = fastParse(value).map((v) => (v === 'null' ? '(blanks)' : v));
    if (values.length <= 3) {
      return values.join(' or ');
    } else {
      const show = values.slice(0, 3);
      const truncated = values.slice(3);
      return `${show.join(' or ')} or ${truncated.length} others`;
    }
  }

  /**
   * format custom value select pill text
   *
   * @private
   * @param {(string | string[])} value
   * @param {string} dbName
   * @param {*} [selectValues]
   * @returns
   * @memberof GlobalFiltersService
   */
  private getCustomValueSelectPillText(
    value: string | string[],
    dbName: string,
    selectValues = []
  ) {
    let text = value;
    if (Array.isArray(value) && dbName === 'state') {
      text =
        value.length > 1
          ? `${WorkTicketStateValues[head(value)]} or ${
              WorkTicketStateValues[last(value)]
            }`
          : WorkTicketStateValues[head(value)];
    } else if (Array.isArray(value) && dbName === 'states') {
      text = value.map((v) => titleCase(v)).join(' or ');
    } else if (Array.isArray(value) && dbName === 'isResolved') {
      text = value.map((v) => ResolutionKeys[v]).join(' or ');
    } else if (
      Array.isArray(value) &&
      (dbName === 'teamIds' || dbName === 'createdByTeam')
    ) {
      text = value
        .map((v) => selectValues.find((t) => t?.value === v)?.label)
        .join(' or ');
    } else if (!Array.isArray(value) && dbName === 'coveredSource') {
      text = titleCase(value);
    } else if (!Array.isArray(value) && dbName === 'activeWork') {
      text = selectValues.find((o) => o?.value === value)?.label;
    } else if (Array.isArray(value) && dbName === 'assetCriticality') {
      text = value.map((v) => ASSET_CRITICALITY_MAP.get(+v));
    }
    return text;
  }

  /**
   * format assignee pill text
   *
   * @private
   * @param {string[]} value
   * @param {Users[]} users
   * @returns
   * @memberof GlobalFiltersService
   */
  private getAssigneeSelectPillText(value: string[], users: Users[]) {
    const displayNames = value.map(
      (id) => users.find((u) => id === u?.id)?.displayName
    );
    return this.getMultiSelectPillText(displayNames);
  }

  /**
   * format assignee pill text using select dropdown options
   *
   * @private
   * @param {string[]} value
   * @param {SelectDropdownOption[]} users
   * @returns
   * @memberof GlobalFiltersService
   */
  private getAssigneeByLabelPillText(
    value: string[],
    users: SelectDropdownOption[]
  ) {
    const displayNames = value.map(
      (id) => users.find((u) => id === u?.value)?.label
    );
    return this.getMultiSelectPillText(displayNames);
  }

  /**
   * generate where queries based off filters
   *
   * @private
   * @param {GlobalFilters} filters
   * @param {AlarmProperty[]} alarmPropsFilter
   * @param {string[][]} room
   * @returns
   * @memberof GlobalFiltersService
   */
  private generateAlarmsFilterQueries(
    filters: GlobalFilters,
    alarmPropsFilter: AlarmProperty[]
  ) {
    let where: CoreAlarmsWhere = {};
    if (filters.searchTerm) {
      where = {
        ...where,
        pattern: `%${filters.searchTerm}%`,
      };
    }
    if (filters.criterion.length) {
      filters.criterion.forEach((criterion) => {
        if (!criterion.detail) {
          return;
        }

        if (criterion.detail.type === 'dateTime') {
          where = {
            ...where,
            [criterion.detail.dbName]: {
              from: criterion.detail.selection.min,
              to: criterion.detail.selection.max,
            },
          };
        } else if (
          [
            'equipmentTypes',
            'equipmentNames',
            'spaceNames',
            'spaceTypes',
            'systemNames',
            'systemTypes',
          ].includes(criterion?.detail?.dbName)
        ) {
          where = {
            ...where,
            [criterion?.detail?.dbName]:
              criterion?.detail?.selection?.value?.map(safeParseNull),
          };
        } else if (
          alarmPropsFilter.find(
            (al) =>
              snakeCase(al.name) === snakeCase(criterion.detail.name) ||
              snakeCase(al.name) === criterion.detail.dbName
          )
        ) {
          if (criterion.detail.selection.value.length) {
            const properties = where.properties ? where.properties : [];
            where = {
              ...where,
              properties: [
                ...properties,
                {
                  type: criterion.detail.dbName,
                  values: criterion.detail.selection.value.map(safeParseNull),
                },
              ],
            };
          }
        } else if (BasFilters.includes(criterion.detail.dbName)) {
          if (criterion.detail.selection.value.length) {
            const basAttributes = where.basAttributes
              ? where.basAttributes
              : [];
            where = {
              ...where,
              basAttributes: [
                ...basAttributes,
                {
                  type: criterion.detail.dbName,
                  values: criterion.detail.selection.value.map(safeParseNull),
                },
              ],
            };
          }
        } else if (criterion.detail.type === 'bool') {
          if (criterion.detail.dbName === 'minNuisance') {
            where = {
              ...where,
              minNuisance: criterion.detail.selection.value === 'true' ? 1 : 0,
            };
          } else {
            where = {
              ...where,
              [criterion.detail.dbName]: safeParseBoolean(
                criterion.detail.selection.value
              ),
            };
          }
        } else if (criterion.detail.type === 'priority') {
          where = {
            ...where,
            ['priorities']: criterion.detail.selection.value.map((d) => +d),
          };
        } else if (criterion.detail.type === 'text') {
          where = {
            ...where,
            [criterion.detail.dbName]: `%${criterion.detail.selection.value}%`,
          };
        } else if (criterion.detail.dbName === 'activeWork') {
          const value = +criterion.detail.selection.value;
          const dbName = value ? 'minActiveWork' : 'maxActiveWork';
          where = {
            ...where,
            [dbName]: value,
          };
        } else if (criterion.detail.dbName === 'basPriority') {
          if (!isNil(criterion?.detail?.selection?.min)) {
            where = {
              ...where,
              minBasPriority: +criterion.detail.selection.min,
            };
          }
          if (!isNil(criterion?.detail?.selection?.max)) {
            where = {
              ...where,
              maxBasPriority: +criterion.detail.selection.max,
            };
          }
        } else if (criterion.detail.dbName === 'Nuisance') {
          where = {
            ...where,
            ...formatNuisance(criterion.detail.selection.value),
          };
        } else if (criterion.detail.dbName === 'isStale') {
          where = {
            ...where,
            isStale: criterion.detail.selection.value === StaleStatus.STALE,
          };
        } else if (criterion.detail.query === 'alarmId') {
          where = {
            ...where,
            [criterion.detail.dbName]: criterion.detail.selection.value.map(
              (value) => {
                if (value.charAt(0).toLowerCase() === 'a') {
                  return +value.slice(1);
                }
                return value;
              }
            ),
          };
        } else if (criterion?.detail?.dbName === 'workTicketIds') {
          where = {
            ...where,
            [criterion?.detail?.dbName]:
              criterion?.detail?.selection?.value.map((v) =>
                isNaN(v) ? +v.substring(1) : v
              ),
          };
        } else if (criterion.detail.type === 'durationMinMax') {
          where = this.timeMinMaxWhere(where, criterion);
        } else if (criterion?.detail?.dbName === 'alarmTypes') {
          where = {
            ...where,
            [criterion.detail.dbName]: criterion?.detail.selection.value.map(
              (v) => (v === BLANK_VALUE ? null : v)
            ),
          };
        } else if (criterion?.detail?.dbName === 'assetCriticality') {
          where = {
            ...where,
            [criterion.detail.dbName]: criterion?.detail.selection.value.map(
              (v) => +v
            ),
          };
        } else if (
          criterion?.detail?.selection?.value?.includes?.('Active Alarms')
        ) {
          return where;
        } else if (criterion.detail) {
          where = {
            ...where,
            [criterion.detail.dbName]: criterion.detail.selection.value,
          };
        }
      });
    }
    return where;
  }

  /**
   * create where clause for querying work tickets
   *
   * @private
   * @param {GlobalFilters} filters
   * @returns {WorkTicketWhere}
   * @memberof GlobalFiltersService
   */
  private generateWorkFilterQueries(filters: GlobalFilters): WorkTicketWhere {
    let where: WorkTicketWhere = {};

    if (filters.searchTerm) {
      where = {
        ...where,
        pattern: `%${filters.searchTerm}%`,
      };
    }

    if (filters.criterion.length) {
      filters.criterion.forEach((criterion) => {
        if (criterion.detail) {
          if (criterion.detail.dbName === 'isResolved') {
            where = this.resolutionWhere(where, criterion);
          } else if (criterion.detail.type === 'dateTime') {
            where = this.dateTimeWhere(where, criterion);
          } else if (criterion.detail.type === 'minMax') {
            where = this.minMaxWhere(where, criterion);
          } else if (
            criterion.detail.type === 'durationMinMax' ||
            criterion.detail.type === 'timeMinMax'
          ) {
            where = this.timeMinMaxWhere(where, criterion);
          } else if (criterion.detail.type === 'userSelect') {
            where = this.userSelectWhere(where, criterion);
          } else if (criterion.detail.type === 'bool') {
            where = this.valueBoolWhere(where, criterion);
          } else if (
            criterion.detail.dbName === 'alarmIds' ||
            criterion.detail.name === 'Alarm ID'
          ) {
            where = this.alarmIdsWhere(where, criterion);
          } else if (
            criterion.detail.dbName === 'teamIds' ||
            criterion.detail.dbName === 'externalSystemId'
          ) {
            where = this.customSelectWhere(where, criterion);
          } else if (criterion.detail.query === 'workTickets') {
            where = this.workIdsWhere(where, criterion);
          } else {
            where = this.valueWhere(where, criterion);
          }
        }
      });
    }
    return where;
  }

  /**
   * generate where for alarm ids
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private alarmIdsWhere(where: WorkTicketWhere, criterion: CriterionSelection) {
    return {
      ...where,
      [criterion.detail.dbName]: criterion.detail.selection.value.map(
        (value) => {
          if (value.charAt(0).toLowerCase() === 'a') {
            return +value.slice(1);
          }
          return value;
        }
      ),
    };
  }

  /**
   * generate where for work ids
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private workIdsWhere(where: WorkTicketWhere, criterion: CriterionSelection) {
    return {
      ...where,
      [criterion.detail.dbName]: criterion.detail.selection.value.map(
        (value) => {
          if (value.charAt(0).toLowerCase() === 'w') {
            return +value.slice(1);
          }
          return value;
        }
      ),
    };
  }

  /**
   * generate where for custom
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private customSelectWhere(
    where: WorkTicketWhere,
    criterion: CriterionSelection
  ) {
    return {
      ...where,
      [criterion?.detail?.dbName]: Array.isArray(criterion?.detail?.selection)
        ? criterion?.detail?.selection
        : criterion?.detail?.selection?.value,
    };
  }

  /**
   * generate where clause for boolean selection filters
   *
   * @private
   * @param {WorkTicketWhere} any
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private valueBoolWhere(where: any, criterion: CriterionSelection) {
    return {
      ...where,
      [criterion.detail.dbName]: safeParseBoolean(
        criterion.detail.selection.value
      ),
    };
  }

  /**
   * generate where clause for value selection filters
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private valueWhere(where: WorkTicketWhere, criterion: CriterionSelection) {
    const dbName = criterion.detail.dbName;
    let value = criterion.detail.selection.value;
    if (Array.isArray(value)) {
      value = value.map((v) => (v === 'null' ? null : v));
    }
    return {
      ...where,
      [dbName]: value,
    };
  }

  /**
   * generate a where clause for dataTime filters
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private dateTimeWhere(where: WorkTicketWhere, criterion: CriterionSelection) {
    return {
      ...where,
      [criterion.detail.dbName]: {
        from: criterion.detail.selection.min,
        to: criterion.detail.selection.max,
      },
    };
  }

  /**
   * generate a where clause for min max filters
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private minMaxWhere(where: WorkTicketWhere, criterion: CriterionSelection) {
    return {
      ...where,
      [`${criterion.detail.dbName}Min`]: criterion.detail.selection.min,
      [`${criterion.detail.dbName}Max`]: criterion.detail.selection.max,
    };
  }

  /**
   * generate a where clause for time min max filters
   *
   * @private
   * @param {any} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private timeMinMaxWhere(where: any, criterion: CriterionSelection) {
    const selection = criterion?.detail?.selection;
    const min = milliseconds({
      days: selection?.minDays || 0,
      hours: selection?.minHrs || 0,
      minutes: selection?.minMins || 0,
    });
    const max = milliseconds({
      days: selection?.maxDays || 0,
      hours: selection?.maxHrs || 0,
      minutes: selection?.maxMins || 0,
    });
    const minMax = {};
    Object.assign(
      minMax,
      min > 0 ? { [`${criterion.detail.dbName}Min`]: min } : null,
      max > 0 ? { [`${criterion.detail.dbName}Max`]: max } : null
    );
    return {
      ...where,
      ...minMax,
    };
  }

  /**
   * generate a where clause for user select filters
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private userSelectWhere(
    where: WorkTicketWhere,
    criterion: CriterionSelection
  ) {
    const userWhere: Partial<WorkTicketWhere> = {};
    const values = fastParse(
      criterion?.detail?.selection?.value || criterion?.value || []
    );
    if (values.includes(AUTOMATION_RULE_CREATOR.value)) {
      userWhere.isCreatedByAutomationRule = true;
      remove(values, (val: string) => val === AUTOMATION_RULE_CREATOR.value);
    }
    if (values.length > 0) {
      userWhere[criterion.detail.dbName] = values;
    }
    return {
      ...where,
      ...userWhere,
    };
  }

  /**
   * generate a where clause for resolution
   *
   * @private
   * @param {WorkTicketWhere} where
   * @param {CriterionSelection} criterion
   * @returns
   * @memberof GlobalFiltersService
   */
  private resolutionWhere(
    where: WorkTicketWhere,
    criterion: CriterionSelection
  ) {
    const selection = criterion?.detail?.selection?.value;
    const isResolved = this.getResolutionValue(selection);
    return {
      ...where,
      isResolved,
    };
  }
}
